module.exports.PageLinks = {
    about: "about-hardisty",
    general_enquiry: "general-enquiry",
    news_insight: `about-hardisty/insights`,
    branch_finder: "contact/branch-finder",
    area_cover: "about-hardisty/areas-we-cover",
    branch_contact: "contact-us",
    team_contact: "contact-us",
    teams: "contact/meet-the-team",
    request_valuation: "property-valuation",
    privacy_policy: "privacy-policy",
    book_a_viewing: "book-a-viewing",
    book_a_valuation: "property-valuation/home-visit-valuation",
    send_to_friend: "send-to-friend",
    property_to_rent: "property/for-sale",
    property_for_sale: "property/for-sale",
    reviews: "about-hardisty/customer-reviews",
    property_management: "landlords/property-management",
    financial_services: `financial-services`,
}